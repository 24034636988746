import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataserviceService {

  public items: any = [];

  constructor() {

    this.items = [
      { title: "CPU" },
      { title: "WAN" },
      { title: "WWW" },
      { title: "BOI" },
      { title: "USD" },
      { title: "INR" }
    ];

  }

 filterItems(searchTerm) {
    return this.items.filter(item => {
      return item.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
  }


}
