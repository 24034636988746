import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { RestApiService } from 'src/app/services/rest-api.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.page.html',
  styleUrls: ['./categories.page.scss'],
})
export class CategoriesPage implements OnInit {

  categories: any;
  data: any = [];
  categoryName: string = "";
  categorieslist: string[] = [];

  @ViewChild(IonContent) content: IonContent;
  offsetTop = 0;
  name: string = "ED";

  constructor(private restApi: RestApiService) {

  }

  ngOnInit() {
    this.initializeData();
  }

  initializeData() {
    this.restApi.getAllCategories().subscribe(data => {
      this.data = data;
      this.categoryName = this.data.categoryName;
      for (let category of data) {
        this.categoryName = category.categoryName;
        this.categorieslist.push(this.categoryName);
        console.log(this.categoryName);
        console.log(category.categoryUrl);
      }
    })
  }

  scrollBottom() {
    this.content.scrollToBottom();
  }

  scrollTop() {
    this.content.scrollToTop();
  }

  onScroll(event) {
    this.offsetTop = event.detail.scrollTop;
  }

}
