import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AboutEdTechTermsPageRoutingModule } from './about-ed-tech-terms-routing.module';

import { AboutEdTechTermsPage } from './about-ed-tech-terms.page';
import { TopbannerPageModule } from 'src/app/shared/components/topbanner/topbanner.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AboutEdTechTermsPageRoutingModule,
    TopbannerPageModule
  ],
  declarations: [AboutEdTechTermsPage]
})
export class AboutEdTechTermsPageModule { }
