import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestApiService } from 'src/app/services/rest-api.service';

@Component({
  selector: 'app-about-ed-tech-terms',
  templateUrl: './about-ed-tech-terms.page.html',
  styleUrls: ['./about-ed-tech-terms.page.scss'],
})
export class AboutEdTechTermsPage implements OnInit {

  urlPath: String;
  content: String;
  image: String;
  dttLogoUrl:string;
  copyrightLogoUrl:string;
  aboutNAAD:string;


  constructor(private router: Router,
    private restApi:RestApiService) {
    this.urlPath = this.router.url;
  }

  ngOnInit() {

    if (this.urlPath == "/home/about-definetestterms") {
      this.restApi.getProperty("dttLogoUrl").subscribe(data => {
        this.dttLogoUrl = data.propertyValue;
        this.image=this.dttLogoUrl;
      })
      this.restApi.getProperty("aboutNAAD").subscribe(data => {
        this.content = data.propertyValue;
      })
    }

    if (this.urlPath == "/home/about-esp") {
      this.restApi.getProperty("copyrightLogoUrl").subscribe(data => {
        this.copyrightLogoUrl = data.propertyValue;
        this.image=this.copyrightLogoUrl;
      })

      this.restApi.getProperty("aboutESP").subscribe(data => {
        this.content = data.propertyValue;
      })
    }
  }
}
